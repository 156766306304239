'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetManager

 # @description

###
angular
  .module 'mundoAdmin.assets'
  .factory 'AssetManager', [
    'Restangular'
    'TenantManager'
    'AssetTypesManager'
    '_'
    'MundoDefaultManager'
    '$mdDialog'
    'AssetDataManager'
    (
      Restangular
      TenantManager
      AssetTypesManager
      _
      MundoDefaultManager
      $mdDialog
      AssetDataManager
    )->
      AssetManagerBase = new MundoDefaultManager()

      AssetManagerBase.setUrl('assets')
      AssetManagerBase.setAutoTenant()
      AssetManagerBase.setNewObject(['label', 'assetType', 'comments'])
      AssetManagerBase.setUpdateObject(['label', 'assetType', 'comments'])
      AssetManagerBase.setSearchFields(['label', 'assetType.label'])
      AssetManagerBase.templateOptions = {
        folder: 'mundo-admin/assets/views/data'
      }
      AssetManagerBase.editPermission = 'manage all MundoMosaAssetBundle:Asset entities'

      AssetManagerBase.getNewForm = ->
        [
          key: 'label'
          type: 'input'
          name: 'label'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'assetType'
          type: 'select'
          name: 'assetType'
          templateOptions:
            label: 'Type'
            placeholder: 'type'
            required: true
            labelProp: "label"
            valueProp: "label"
            options: AssetTypesManager.getListCustom()
        ,
          key: 'comments'
          type: 'textarea'
          name: 'comments'
          templateOptions:
            label: 'Comments'
            placeholder: 'comments'
            required: false
            rows: 5
        ]

      AssetManagerBase.getEditForm = (data)->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'assetType'
          name: 'assetType'
          type: 'select'
          defaultValue: data.assetType
          templateOptions:
            label: 'Type'
            placeholder: 'type'
            required: true
            labelProp: "label"
            valueProp: "label"
            options: AssetTypesManager.getListCustom()
        ,
          key: 'comments'
          name:'comments'
          type: 'textarea'
          defaultValue: data.comments
          templateOptions:
            label: 'Comments'
            placeholder: 'comments'
            required: false
            rows: 5
        ]

      AssetManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'assetType'
          title: 'datatable.label.assetType'
          sort: 'assetType'
        ,
        #   key: 'tenant.label'
        #   title: 'datatable.label.tenant'
        #   hideInList: true
        # ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
          hideInList: true
        ]

      AssetManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/assets/views/asset-detail.tpl.html'
  #   data
      AssetManagerBase.addData = (id, data) ->
        AssetDataManager.new(id, data)

      AssetManagerBase.removeData = (assetId, dataId) ->
        AssetDataManager.removeData(assetId, dataId)

      AssetManagerBase.getDataFieldsByType = (type) ->
        AssetDataManager.getExtraFormByType(type)

      addData =
        [
          icon: 'description'
          text: 'datatable.tooltips.addAssetData'
          permission: AssetManagerBase.editPermission
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/assets/views/asset-data.modal.tpl.html'
              controller: 'AssetDataTypeCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
        ]

      AssetManagerBase.addExtraAction(addData)

      AssetManagerBase
  ]
